const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100 d-flex flex-column align-items-center justify-content-center">
      <img
        className="fallback-logo"
        src={require("../../../assets/images/logo/logo.png").default}
        alt="logo"
        width="100vw"
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
